.slider {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  margin-bottom: 1rem;

  .slide {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    transition: top 0.25s ease-in-out, left 0.25s ease-in-out, opacity 0.75s ease-in-out;
  }
}

.slider-btn {
  &.disabled {
    cursor: default;
    opacity: 0.5;
  }
}
